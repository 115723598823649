@import "scss/index";
.buttonsPanel {
  @include big-shadow-titanic-1;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 802;
}

.calcWidth {
  width: calc(100% - 25.3%);
}

body {

  &:global(.w-full) {

    .calcWidth {
      width: 100%;
    }
  }
}

