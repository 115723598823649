@import "scss/index";
.root {
  background-color: $color-gladiator;

  .opacity {
    opacity: 0.72;
  }

  &:hover {
    background-color: $color-interstellar;

    .opacity {
      opacity: 1;
    }
  }

  &.active {
    background-color: $color-titanic;

    .opacity {
      opacity: 1;
    }

    .indicator {
      background-color: $color-rocky;
    }
  }
}

.indicator {
  position: absolute;
  top: calc(50% - 32px / 2);
  left: 0;
  width: 4px;
  height: 32px;
  background-color: transparent;
}

.subMenu {
  padding-left: 28px;
}