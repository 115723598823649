@import "scss/index";
.root {
  background-color: $color-gladiator;

  .text {
    opacity: 0.72;
  }

  &:hover {
    background-color: $color-interstellar;

    .text {
      opacity: 1;
    }
  }

  &.active {
    background-color: $color-titanic;

    .text {
      opacity: 1;
    }

    .indicator {
      background-color: $color-rocky;
    }
  }
}

.indicator {
  position: absolute;
  top: calc(50% - 32px / 2);
  left: 0;
  width: 4px;
  height: 32px;
  background-color: transparent;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
